<template>
    <vue-perfect-scrollbar
        :settings="{suppressScrollX: true, wheelPropagation: false}"
        class="h-100 rtl-ps-none ps scroll"
        style="height: 100%;"
    >
        <div class="pa-5 relative">
            <v-app-bar class="pt-1" fixed flat>
                <div class="d-flex justify-space-between align-center w-full">
                    <h6 class="ma-0">Notifications</h6>
                    <slot name="notificationDrawerCloseButton"></slot>
                </div>
            </v-app-bar>
        </div>

        <div class="pa-5 mt-10">
            <!-- <div class="heading-label pa-0 mb-8">Notifications</div> -->

            <div v-for="(item, index) in items" :key="index">
                <div class="d-flex justify-space-between mb-8">
                    <div class="d-flex">
                        <v-avatar
                            size="40"
                            class="mr-2"
                            :color="item.iconColor"
                        >
                            <v-icon :class="item.iconColorText">{{
                                item.iconName
                            }}</v-icon>
                        </v-avatar>
                        <div>
                            <p class="font-weight-medium body-2 ma-0 ">
                                {{ item.title }}
                            </p>
                        </div>
                    </div>
                    <div>
                        <span class="text--disabled caption">{{
                            item.time
                        }}</span>
                    </div>
                </div>
                <v-divider class="py-2"></v-divider>
            </div>
        </div>
    </vue-perfect-scrollbar>
</template>
<script>
export default {
    data() {
        return {
            items: [
                {
                    iconName: 'mdi-cloud-upload',
                    iconColor: 'bg-blue-200',
                    iconColorText: 'info--text',
                    title: 'Alerts can go here',
                    time: '15 min ago'
                },
            ]
        }
    }
}
</script>
